
.xx-small_ { font-size: 9px }
.x-small_  { font-size: 10px }
.small_    { font-size: 12px }
.medium_   { font-size: 15px }
.large_    { font-size: 18px }
.x-large_  { font-size: 24px }
.xx-large_ { font-size: 32px }

.roboto {
    font-family: "Roboto", sans-serif;
}
.encode-sans {
    font-family: "Encode Sans", sans-serif;
}
.helvetica {
    font-family: "Helvetica", sans-serif !important;
}

.text-gray {
    color: #BFC5D2 !important;
}

.text-gray2 {
    color: $gray7 !important;
}

.text-gray3 {
    color: $gray8 !important;
}

.text-lightgray {
    color: $gray3 !important;
}

.encode-sans-12 {
    font-family: "Encode Sans";
    font-size: 12px;
    letter-spacing: 1.13px;
    line-height: 15px;
}
.encode-sans-15 {
    font-family: "Encode Sans";
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 21px;
}
.encode-sans-28 {
    font-family: Encode Sans, sans-serif;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
}
.encode-sans-43 {
    //font-family: "Encode Sans", sans-serif;
    font-family: "Encode Sans";
    font-size: 43px;
    font-weight: 500;
    letter-spacing: -0.54px;
    line-height: 53px;
}
.helvetica-12 {
    font-family: "Helvetica", sans-serif !important;
    font-size: 12px !important;
    letter-spacing: 0 !important;
    line-height: 22px !important;
}
.roboto-10 {
    font-family: "Roboto Light", sans-serif !important;
    font-size: 10px !important;
    letter-spacing: 0;
    //line-height: 12px;

    &.text-grey {
        color: $gray4;
    }
    &.text-green {
        color: $green1;
    }
    &.text-red {
        color: $red3 !important;
    }
}
.roboto-12 {
    font-family: "Roboto Light", sans-serif !important;
    font-size: 12px !important;
    font-weight: 500;
    letter-spacing: 1.12px;
    line-height: 14px;

    &.text-dark {
        color: $gray6;
    }
    &.text-grey {
        color: $gray4 !important;
    }
    &.text-red {
        color: $red3 !important;
    }
}
.roboto-13 {
    font-family: "Roboto Light", sans-serif !important;
    font-size: 13px !important;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;

    &.text-grey {
        color: $gray4 !important;
    }
}
.roboto-15 {
    color: #2E384D !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;

    &.text-gray {
        color: #8798AD !important;
    }
    &.text-gray3 {
        color: $gray8 !important;
    }
}
.roboto-16 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;

    &.text-success { color: $green3 !important; }
    &.text-red { color: $red3 !important; }
}

.tria-input-font {
    color: #2E384D !important;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
}

p.sub-header {
    color: #2E384D;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 500;
  } 

.text-black {
    color: $gray6 !important;
}
.text-white {
    color: #fff;
}

i.text-gray {
    color: #BFC5D2;
}
i.text-green {
    color: $green1;
}
i.text-success {
    color: $green3 !important;
}