.tria-input {
    box-sizing: border-box;
    border: 1px solid #E0E7FF !important;
    border-radius: 5px;
    background-color: rgba(224,231,255,0.2);
    font-size: $font-size-sm;
    width: 100%;
    min-height: 40px;
    padding: 10px;

    & + .clear-btn {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 12px;
    }

    &.no-outline {
      outline: none;
    }

    &.is-error {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & + .error-tooltip {
      width: 100%;
      height: 20px;
      background-color: $blue1;
      border-radius: 0 0 5px 5px;
      padding: 2px 15px;
    }
}
.tria-input:focus {
    background-color: rgba(224,231,255,0.2);
    box-shadow: none;
}
.tria-input > .rw-input {
    background-color: rgba(224,231,255,0.2);
    color: #222a42;  
}
.tria-input > .rw-input:active {
    background-color: rgba(224,231,255,0.2);
    color: #222a42;  
}
.tria-input .rw-widget-input {
    box-shadow: none;
}


.tria-text-input {
    max-width: 100%;
    width: 100%;
    padding: 10px 10px 10px 10px;
    margin: 0;

    //box-sizing: border-box;
    border: 1px solid #E0E7FF !important;
    border-radius: 5px;
    background-color: rgba(224,231,255,0.2);
    outline: none;

    color: #2E384D;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 18px;
}
textarea.tria-text-input {
    resize: none;
    height: auto;
}

.switch {
    position: relative;
    display: inline-block;
    width: 36px;
    min-width: 36px;
    height: 20px;
}
 /* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* ToggleSwitch / slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #24AECE;
}
input:focus + .slider {
    box-shadow: 0 0 1px #24AECE;
}
input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}

.react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
}

.tria-range-input {
  background: linear-gradient(to right, $blue1 0%, $blue1 98%, $gray5 98%, $gray5 100%);
  border-radius: 8px;
  height: 5px;
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

.tria-range-input::-webkit-slider-thumb{
  border: 4px solid $blue1;
  height: 16px;
  width: 16px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}


.tria-dropdown {
    position: relative;
    height: 40px;
  }
  .tria-dropdown-selector {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px 15px;
    cursor: pointer;
  
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    //background-color: rgba(224,231,255,0.2);
    background-color: #fff;
  
    & i {
      font-size: 12px;
      color: $gray2;
    }
    //font-family: 'Roboto', sans-serif;
    font-family: "Encode Sans", sans-serif;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0;
    color: $blue1;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .tria-dropdown-options {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    border: 1px solid $gray5;
    border-top: 0px;
    border-radius: 5px;
    background-color: #fff;
    z-index: 1;

    max-height: 300px;
    overflow-y: scroll;
  }
  
  .tria-dropdown-selector.is-active {
    border-radius: 5px 5px 0px 0px;
  
    & + .tria-dropdown-options {
      border-radius: 0px;
    }
  }
  
  .tria-dropdown-option {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 10px;
  
    font-family: "Encode Sans", sans-serif;
    font-size: 15px;
    color: #B0BAC9;
    font-weight: 300;
  
    border-left: 3px solid transparent;
  
    cursor: pointer;
  
    & i {
      color: #B0BAC9;
    }
  
    & div:first-child {
      width: 30%;
    }
    & div:last-child {
      width: 70%;
      line-height: 18px;
    }
    
    &:hover {
      border-left: 3px solid $blue1;
      background-color: $blue1-15;
      & div:first-child {
        color: $blue1;
      }
    }
  }


.button-dropdown-card {
    border: solid 1px rgba(46,91,255,0.08); 
    box-shadow: 0 10px 20px 10px rgba(46, 91, 255, 0.075) !important;
}