$primary-color: #7392fa;
$background-color-light: white;
$header-color: #333;
$placeholder-color: #ccc;
$border-radius: 5px;
$item-hover-background: #f0f0f0;
$item-selected-background: #e0e0ff;
$box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

.main-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.round-button {
    width: 25px; 
    height: 25px; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #09618c;
    color: white; 
    border: none;
    cursor: pointer;
  }
  
  .round-button i {
    font-size: 12px;
    color: white;
  }
  
  .round-button:hover {
    background-color: #0098f1; 
  }

.enhanced-search-bar {
    display: grid;
    grid-template-columns: 43% 34% 23%;
    gap:8px;
    margin-top: 8px;
    margin-bottom: 8px;

    input {
        flex-grow: 1;
        background-color: $background-color-light;
        border-radius: $border-radius;
        border: 1px solid $primary-color;
        box-shadow: $box-shadow;
        padding: 0 12px;
        font-size: 14px;
        height: 44px;

        &::placeholder {
            color: darken($placeholder-color, 10%);
        }

        &:focus,
        &:active {
            outline: none;
            box-shadow: 0 0 5px $primary-color;
            border-color: $primary-color;
        }
    }

    .form-check .form-check-sign::before{
        border: 1px solid $primary-color;

    }
}

.button-group {
    display: grid;
    grid-template-columns: 43% 34% 23%;
    gap: 8px;
    height: 32px;

    .search-button,
    .clear-filters-button {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        border: none;
        border-radius: $border-radius;
        font-size: 12px;
        font-weight: bold;
        color: white;
        cursor: pointer;
        transition: background-color 0.3s, transform 0.2s;
        width: 100%;

        i {
            margin-right: 8px;
        }
    }

    .search-button {
        background-color: #09618c;

        &:hover {
            background-color: darken(#09618c, 10%);
        }

        &:active {
            background-color: lighten(#09618c, 10%);
        }
    }

    .clear-filters-button {
        background-color: #0098f1;

        &:hover {
            background-color: darken(#0098f1, 10%);
        }

        &:active {
            background-color: lighten(#0098f1, 10%);
        }
    }
}

.select-group {
    display: flex;
    flex-direction: row;
    gap: 7px;
    width: 50%;

    .custom-dropdown {
        position: relative;
        border: 1px solid $primary-color;
        border-radius: $border-radius;
        background-color: $background-color-light;
        cursor: pointer;
        display: flex;
        width: 100%;

        .dropdown-header {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            font-size: 11px;
            color: $header-color;
            width: 100%;

            &:focus {
                outline: none;
                box-shadow: 0 0 5px $primary-color;
            }

            .selected-tags {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                align-items: unset;
            }

            .selected-tag {
                background-color: $item-selected-background;
                border-radius: $border-radius;
                padding: 0px 5px;
                align-items: center;
                display: flex;
                font-size: xx-small;

                .close-icon {
                    background: none;
                    border: none;
                    color: $header-color;
                    cursor: pointer;
                    margin-left: 5px;
                    font-size: x-small;
                    padding-bottom: 3px;
                }
            }

            .selected-label,
            .placeholder {
                color: $placeholder-color;
                flex-grow: 1;
                text-align: left;
            }

            .placeholder {
                display: flex;
                align-items: center;
            }

            .icon {
                color: $primary-color;
            }
        }

        .dropdown-list {
            display: block;
            position: absolute;
            width: 100%;
            max-height: 150px;
            overflow-y: auto;
            border: 1px solid $primary-color;
            border-radius: $border-radius;
            background-color: $background-color-light;
            box-shadow: $box-shadow;
            z-index: 1000;
            top: 100%;
            left: 0;
            margin-top: 5px;

            list-style: none;
            padding: 0;

            .dropdown-item {
                padding: 10px;
                font-size: 10px;
                color: $header-color;
                cursor: pointer;

                &.selected {
                    background-color: $item-selected-background;
                }

                &:hover {
                    background-color: $item-hover-background;
                }
            }
        }
    }
}