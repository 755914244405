
.blade-panel {
    visibility: hidden;
    transition: visibility 0s 0.6s;
}

.blade-panel.blade-panel--is-visible {
    visibility: visible;
    transition: visibility 0s 0s;
}

.blade-panel-header {
    position: fixed;
    top: 0;
    width: 95%;
    max-width: 750px;
    height: 50px;
    transition: transform 0.3s 0s;
    transform: translateY(-50px);
    right:0;
    margin-top:20px;
}

.blade-panel-header h1 {
    color: $dark-gray;
    font-size: $font-size-xl;
}

.blade-panel--is-visible .blade-panel-header {
    transition: transform 0.3s 0.3s;
    transform: translateY(0px);
}

.blade-panel-container {
    position: fixed;
    width: 90%;
    max-width: 600px;
    height: 100%;
    top: 0;
    transition: transform 0.3s 0.3s;
    right: 0;
    transform: translate3d(100%, 0, 0);
    background-color: #fff;
    z-index: 999;
    overflow: scroll;
}

.blade-panel--is-visible .blade-panel-container {
    transform: translate3d(0, 0, 0);
    transition-delay: 0s;
}

.blade-panel-content {
    padding: 70px 5% 5% 5%;
    color: $black;
    max-height: 100%;
    height: 100%;
}

.blade-panel-content p {
    color: $black;
}

.blade-panel-close-button {
    margin-top:-40px;
    z-index: 999;
}

.blade-panel-close-button span {
    color: $black;
}

.blade-panel select,
.blade-panel select .active {
    background-color: $white;
}

.blade-backdrop {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(20px);
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .3);
}
