

.Login {
  max-width: 400px;
  padding-top:25px;
}

.Login .card .card-body {
  padding: 25px;
}

.background-blue {
  background-color: #0C618D !important;
}