.red {
    color: #D63649;
}
.green {
    color: #33AC2E;
}
.green-50op {
    color: rgba(51, 172, 46, 0.50);
}
.blue {
    color: #2E5BFF;
}
.gray {
    color: #BFC5D2;
}
.yellow {
    color: #F0E65B;
}

.content-container {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    @media (min-width: 576px) {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 50px
    }
    @media (min-width: 992px) {
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 50px
    }
}

.hs {
    @media (max-width: 768px) {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(4, calc(50% - 40px));
        grid-template-rows: minmax(250px, 1fr);
        overflow-x: scroll;
        scroll-snap-type: x proximity;
    }
    /*@media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
    }*/
  }

.my-hs {
    @media (max-width: 768px) {
        display: flex;
        overflow-x: scroll;
    }
    @media (min-width: 768px) {
        display: flex;
    }
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-x-scroll-tablet {
    @media (max-width: 992px) {
        overflow-x: scroll;
    }
}

.overflow-x-scroll-lg {
    @media (max-width: 1200px) {
        overflow-x: scroll;
    }
}

h1.tria-header, .tria-header a  {
    color: #2E384D;
    font-family: Encode Sans, sans-serif !important;
    font-size: 28px !important;
    font-weight: 500 !important;
    letter-spacing: 0;
    line-height: 32px;
}
.tria-header a {
    color: #2E5BFF !important;
}
.tria-header-container {
    padding-bottom: 15px;
    @media (min-width: 576px) {
        padding: 0px;
    }
    @media (min-width: 992px) {
        padding-bottom: 20px;
    }

    & h1 {
        margin-bottom: 0px;
    }
}

.bar {
    height: 4px; 
    width: 19px;
    border-radius: 99px;
    margin-top: 3px; 
    margin-bottom: 3px; 
    margin-left: 2px; 
    margin-right: 2px;
}

.collapse-button {

    width: 100%;
    height: 40px;
    border-radius: 4px;
    color: white;

    @media (min-width: 576px) {
        width: 225px;
    }
}
.collapse-button,
.collapse-button:hover,
.collapse-button:active,
.collapse-button:focus,
.collapse-button:focus:active {
    background: #2E5BFF !important;
}

.gray-button {
    height: 40px;
    border-radius: 4px;
    color: #8798AD !important;

    font-family: 'Encode Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
}
.gray-button,
.gray-button:hover, 
.gray-button:active, 
.gray-button:focus, 
.gray-button:focus:active {
    background-color: #F4F6FC !important;
    background: #F4F6FC !important;
}


// Status Icon Token
.status-icon-token {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;

    i, img { margin: 0 auto; }

    & + i {
        top: 2.5%;
        right: 2.5%;
    }

    .fixed-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.status-icon-token[sz="sm"] {
    width: 30px;
    min-width: 30px;
    height: 30px;

    & + i {
        top: 0;
        right: 0;
        border: 2px solid $white;
        border-radius: 50%;
    }
}

.status-icon-token[sz="lg"] {
    width: 70px;
    min-width: 70px;
    height: 70px;
    i { font-size: 30px; }

    & + i {
        top: 8%;
        right: 8%;
    }
}
.status-icon-token[sz="md-lg"] {
    width: 50px;
    min-width: 50px;
    height: 50px;
    i { font-size: 30px; }

    & + i {
        top: 0;
        right: 0;
        border: 2px solid $white;
        border-radius: 50%;
    }
}
.status-icon-token[color="green"] {
    background-color: $green1-15;
    i { color: $green1; }
}
.status-icon-token[color="yellow"] {
    background-color: $yellow1-15;
    i { color: $yellow1; }
}
.status-icon-token[color="red"] {
    background-color: $red1-15;
    i { color: $red1; }
}
.status-icon-token[color="blue"] {
    background-color: $blue1-15;
    i { color: $blue1; }
}
.status-icon-token[color="gray"] {
    background-color: $gray4-15;
    i { color: $gray4; }
}
.status-icon-token[color="light-gray"] {
    background-color: $gray2-15;
    i { color: $gray2; }
}
.status-icon-token[color="purple"] {
    background-color: $purple1-15;
    i { color: $purple1; }
}


// Status Icon
i.status-icon[color="red"] {
    color: $red1;
}
i.status-icon[color="green"] {
    color: $green1-50;
}
i.status-icon[color="yellow"] {
    color: $yellow1;
}
i.status-icon[color="blue"] {
    color: $blue1;
}
i.status-icon[color="gray"] {
    color: $gray2;
}
i.status-icon[color="purple"] {
    color: $purple1;
}

// Health Bars
.health-bar[color="red"] {
    background-color: $red2;
}
.health-bar[color="green"] {
    background-color: $green2;
}
.health-bar[color="yellow"] {
    background-color: $yellow1;
}
.health-bar[color="gray"] {
    background-color: $gray2;
}
.health-bar.empty-bar {
    border: 1px solid $gray2;
    background-color: $gray2-30;
}

.health-bar-label {
    color: #8798AD !important;
    font-family: 'Roboto', sans-serif;
    font-size: 6px;
    letter-spacing: 0;
}

.tria-btn-blue,
.tria-btn-blue:hover,
.tria-btn-blue:active,
.tria-btn-blue:focus,
.tria-btn-blue:focus:active {
    background: #2E5BFF !important;
}

p.label-font {
    color: #8798AD !important;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 22px;
}

.mw-rw-datetime-picker .rw-container {
    height: 100%;
    height: 40px;
    box-sizing: border-box;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    
    .rw-select.rw-select-bordered {
        border-left: 1px solid #E0E7FF;
    }

    .rw-select .rw-btn.rw-btn-select {
        width: 25px;
    } 
}

.mw-rw-datetime-picker.rw-state-focus > .rw-input {
    background-color: rgba(224,231,255,0.2);
    border: 0;
    box-shadow: none;
}

.mw-rw-datetime-picker.rw-state-focus > .rw-container {
    box-shadow: none;
}

.rw-dropdown-font {
    & > .rw-input {
        color: $gray4;
        font-family: 'Roboto', sans-serif;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 18px;
    }

    &.options-dropdown {
        height: 40px;
        border-color: $gray5;

        & > .rw-input { color: $blue1; }
    }
}

.helvetica-font {
    color: #8798AD !important;
    font-family: Helvetica, sans-serif;;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 22px;
    font-weight: 400;
}

.content-blade-backdrop {
    content: ' ';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(5px);
    width:100%;
    height: 100%;
    z-index: 998;

    &.no-backdrop {
        backdrop-filter: none;
    }
}

.content-blade {
    position: fixed;
    z-index: 1050;
    background-color: white;
    border: 1px solid rgba(46, 91, 255, 0.08);
    border-radius: 1px;
    box-shadow: $btn-box-shadow;
    overflow: auto;

    .content-blade-header {
        padding: 32px 25px 0 24px;
        background-color: white;

        &.bordered { border-top: 6px solid $blue1; }
    }

    .content-blade-body { padding: 15px 30px; }

    &[side="left"], &[side="right"]  {
        top: 0;
        bottom: 0;
        width: 80%;
        max-height: 100vh;

        @media (min-width: 576px) {
            max-width: 350px;
        }
        @media (min-width: 1200px) {
            max-width: 600px;

            &.large { max-width: 720px; }
        }
    }

    &[side="top"], &[side="bottom"]  {
        left: 0;
        right: 0;
        max-height: 80vh;
    }

    &[side="left"] { left: 0; }
    &[side="right"] { right: 0; }
    &[side="top"] { top: 0; }
    &[side="bottom"] {
        bottom: 0;

        .content-blade-header { padding: 13px 24px 6px 24px; }

        .content-blade-body { padding: 0 24px 30px; }
    }

}

@media screen and (max-width: 992px) {
    .tablet-hidden { display: none; }  
  }
  @media screen and (max-width: 576px) {
    .mobile-hidden { display: none; }  
  }
  @media screen and (min-width: 576px) {
    .mobile-shown { display: none; }  
  }

.text-action {
    color: #2E5BFF !important;
    font-family: 'Encode Sans', sans-serif;
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
    cursor: pointer;
}

.card-field-value .field-label {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 0;
    color: $gray6;
}
.card-field-value .field-value {
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 13px;
    color: $gray4;

    margin-top: .3rem;
    word-break: break-word;
}
.card-sub-header {
    color: #8798AD !important;
    font-family: 'Encode Sans', sans-serif;
    font-size: 13px;
    letter-spacing: 1.21px;
    line-height: 16px;
    text-transform: uppercase;

    margin-top: 1.5rem;
    margin-bottom: 1rem;
}

.card-content {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    @media (min-width: 768px) {
        padding-left: 1.5rem;
        padding-right: 0rem;
    }
    @media (min-width: 992px) {
        padding-left: 1.5rem;
        padding-right: 3rem;
    }
    
    margin-bottom: 1.5rem;
}

.mw-card-content {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @media (min-width: 768px) {
        padding-left: 1.1rem;
        padding-right: 1.5rem;
    }
}

.break-word {
    word-break: break-word;
}

.tria-alert {
    @media (min-width: 1200px) {
        padding-left: 90px;
    }
}
.card.card-nextgen {
  .card-header {
    background: white;
    border-bottom: 0;
  }
  .card-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #8798AD;
    margin-bottom: 0;
  }
  h5, label:not(.btn) {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #8798AD;
  }

  .col-border-right {
    border-right: solid 2px #d8d8d8;
  }
  .active{
    color: #00c1d4!important;
  }

  .btn-outline-primary {
    background: white !important;
    border: solid 1px #ddd;
    font-weight: normal;
    color: #000;
    &.active {
      border-color: #2E5BFF;
      color: inherit;
    }
    .fas {
      color: #2E5BFF;
    }
    .small {
      color: #8899AE;
    }
  }
}

.upsell-card {
  background-size: cover;
  font-family: 'Encode Sans', sans-serif;
  padding: 15px;

  .title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .body {
    display: flex;
    flex-direction: column;

    .hero-wrapper {
      flex: 1;
      align-self: stretch;
      margin-bottom: 10px;

      .hero { width: 100%; }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: flex-end;
      padding: 0;

      .description {
        align-self: stretch;
        text-align: center;
      }

      .action-btn {
        display: block;
        align-self: stretch;
        margin-top: 15px;
      }
    }
  }

  &.desktop {
    padding: 20px 25px;

    .body {
      flex-direction: row;

      .hero-wrapper { margin-bottom: 20px; }

      .content {
        flex: 1;
        margin-left: 10px;
      }

      &.row-reverse {
        flex-direction: row-reverse;

        .content { margin-left: 0; margin-right: 10px; }
      }
    }
  }
}

.ops-form {
  input { font-size: 1rem; }
}

.lh-0 {
    line-height: 0px;
}

.tria-dropdown {

}
.tria-dropdown-btn {
    height: 40px;
    width: 100%;
    min-width: 115px;
    background: white;
    color: $blue1;
    font-family: "Encode Sans", sans-serif; 
    //text-align: center;
    //vertical-align: middle;
    padding: 5px 15px;
    border: 1px solid #E0E7FF;
    border-radius: 5px;
    font-size: 15px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & > i {
        color: #BFC5D2;
//float: right;
    }
    &:focus, &:active {
        outline: none;
        //border: 1px solid #E0E7FF;
    }
}

.dropdown-content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content > ul > li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content > ul > li:hover {background-color: #ddd}



.underline {
    text-decoration: underline;
}

.my-token-line::after {
    content: " ";
    display: block;
    position: absolute;
    height: 5px;
    background: black;
    width: 40px;
    left: 100%;
    top: calc(50% - 2px);
}

@media (max-width: 576px) {
    .no-border-sm { 
      border: 0 !important; }
}

.loading-circle {
    border: 2px solid #DBE9FD;
    border-top: 2px solid $blue1;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg); }
}

.tria-tooltip {
    background: #fff;
    border: 1px solid $blue1;
    color: #2E384D;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}

// Tria table styles in divs
.tria-table-header > div {
  color: #BFC5D2 !important;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  padding: 17px 7px 0;
}
.tria-table-row {
  & > div {
    padding: 15px 10px;
    line-height: 2;
    vertical-align: middle;
  }
  .lh-2 {
    line-height: 2;
  }
}
.tria-table-row.bot-resource > div {
  padding: 12px 7px;
}

.tria-icon {
    font-size: 18px !important;

    &.large {
        font-size: 30px !important;
        font-weight: 900;
    }

    &.medium-lg {
        font-size: 24px !important;
        font-weight: 900;
    }

    &.medium {
        font-size: 14px !important;
        font-weight: 900;
    }

    &.small {
        font-size: 9px !important;
        font-weight: 900;
    }
    &.xs {
        font-size: 6px !important;
        font-weight: 900;
    }
}

.form-check.tria-checkbox {
    .form-check-sign::before,
    .form-check-sign::after{
        border-color: $gray5;
        border-radius: 5px;
        background-color: $gray5-15;
        font-size: 12px !important;
        width: 22px;
        height: 22px;
        top: 50%;
        transform: translateY(-50%);
    }
    
    input[type="checkbox"]:checked + .form-check-sign::before {
        border: 1px solid $blue1;
        background-color: $gray5;
    }

    input[type="checkbox"]:checked + .form-check-sign::after {
        color: $blue1;
        margin-top: 2px;
    }
}

.tria-badge {
    background-color: $white;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    box-shadow: 0 0.3px 10px $blue1-30;
}

.tria-notifications {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    z-index: 10;
}

.tria-resource-card { height: 190px; }
.tria-runbook-card { height: 270px; }

.trend-icon-upward { transform: rotate(45deg); }
.trend-icon-downward { transform: rotate(-45deg); }

.rw-no-btn > span.rw-select-bordered {
    display: none;
}

.tria-separator {
    height: 2px;
    background-color: $blue1-15;
}

.runbook-meta-separator {
    background-color: $blue1-15;
    width: 2px;
    height: 40px;
    margin-left: 19px;
}

.instruction-builder {
    .action-node {
        width: 265px;
        height: 70px;
        background-color: white;
        border: 1px solid $blue1-15;
        border-radius: 5px;

        &.selected {
            border-left-width: 2px;
            border-left-color: $blue1;
        }

        &.has-screenshot {
            height: 170px;
        }

        .action-screenshot {
            max-width: 100%;
            height: 100px;
            object-fit: contain;
        }

        .delete-btn {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
        }
    }

    .node-edge path {
        stroke: $gray5;
        stroke-width: 2px;
    }

    .selector-node {
        background-color: white;
        border: 1px solid $blue1-15;
        border-radius: 5px;

        .integration-card {
            width: 120px;
            height: 120px;
        }
    }
}

.condition-builder-modal {
    transform: none !important;

    @include media-breakpoint-up(xl) {
        max-width: $modal-xl;
    }

    .integration-card + .delete-btn {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
    }

    .group-connector {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
    }
}

.cursor {
    cursor: pointer;
}