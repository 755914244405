.card{
    background: $card-black-background;
    border: 0;
    position: relative;
    width: 100%;
    box-shadow: $box-shadow;


    label{
      color: rgba($white, 0.6);
    }

    .card-title {
      margin-bottom: .75rem;
    }

    .card-body{
      padding: 15px;

      &.table-full-width{
        padding-left: 0;
        padding-right: 0;
      }

      .card-title{
        color: $white;
        text-transform: inherit;
        font-weight: $font-weight-light;
        margin-bottom: .75rem;
      }

      .card-description, .card-category{
        color: rgba($white, 0.6);
      }

    }

    .card-header{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;
      color: rgba($white,0.8);

      .card-title{
          color: $white;
          font-weight: 100;
      }

      .card-category{
        color: $dark-gray;
        margin-bottom: 5px;
        font-weight: 300;
      }
    }

    .card-header-minimal{
      &:not([data-background-color]){
        background-color: transparent;
      }
      padding: 15px 15px 0;
      border: 0;
      color: rgba($black,0.8);

      .card-title{
          color: $black;
          font-weight: 100;
      }

      .card-category{
        color: $dark-gray;
        margin-bottom: 5px;
        font-weight: 300;
      }
    }

    .map{
        border-radius: $border-radius-sm;

        &.map-big{
          height: 420px;
        }
    }

    &.card-white{
      background: $white;

      .card-title{
        color: $black;
      }
      .card-category, .stats{
        color: $card-stats-gray;
      }

      //style for inputs

      @include form-control-placeholder(rgba($black,0.4), 1);
        .has-danger{
          .form-control, .input-group-prepend .input-group-text{
            border-color: $danger-states;
          }
        }

        .input-group-prepend .input-group-text{
          border-color: rgba($black-states,0.2);
          color: $black-states;
        }

        .form-control{
          color: $black;
          border-color: rgba($black-states,0.2);
          &:focus{
            border-color: $primary;
          }
        }
        label:not(.btn){
          color: $default;
        }

      .form-group.no-border,
      .input-group.no-border {
          .form-control,
          .form-control + .input-group-prepend .input-group-text,
          .form-control + .input-group-append .input-group-text,
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text{
            background-color: $opacity-gray-3;
              &:focus,
              &:active,
              &:active{
                background-color: $opacity-gray-5;
              }
          }

          .form-control {
              &:focus {
                  & + .input-group-prepend .input-group-text,
                  & + .input-group-append .input-group-text{
                    background-color: $transparent-bg;
                  }
              }
          }


      }

      .input-group[disabled]{
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text{
              background-color: $black;
          }
      }

      .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
        background: $light-gray;
        border-color: rgba($black-states,0.3);
      }

      .input-group-focus{
          .input-group-prepend .input-group-text,
          .input-group-append .input-group-text,
          .form-control{
            background-color: $white;
            border-color: $primary;
          }

          &.no-border{
              .input-group-prepend .input-group-text,
              .input-group-append .input-group-text{

                  background-color: $opacity-gray-5;
              }
          }
      }
      .input-group-prepend .input-group-text {
          border-right: none;
      }

      .input-group-append .input-group-text {
          border-left: none;
      }

      .has-danger .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: $danger-states;
      }

      .has-success .form-control:focus, .has-success.input-group-focus .input-group-append .input-group-text, .has-success.input-group-focus .input-group-prepend .input-group-text {
        border-color: darken($success, 10%);
      }
    }


    &.card-plain {
      background: transparent;
      box-shadow: none;
    }

    .image{
        overflow: hidden;
        height: 200px;
        position: relative;
    }

    .avatar{
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 15px;
    }

    label{
        font-size: $font-size-sm;
        margin-bottom: 5px;

    }

    .card-footer{
        background-color: transparent;
        border: 0;
        padding: 15px;


        .stats{
            i{
                margin-right: 5px;
                position: relative;

            }
        }

        h6{
          margin-bottom: 0;
          padding: 7px 0;
        }
    }
}

.card-body{
  padding: $card-spacer-y;
}

#db-pane .card-home .card-header .card-title {
  font-size: $font-size-sm;
}

#db-pane .card-home .card-body h3 {
  margin-bottom: 0.25rem;
}

.card-home .card-header {
  padding: 10px 10px 0;
  text-align: center;
}

.card-home .card-title {
  margin-bottom: 0.5rem;
}

@include media-breakpoint-down(sm) {
  .card.card-chart .card-header {
    .btn-group-toggle .tim-icons {
      font-size: .875rem;
      top: -1px;
    }
  }
}

.tria-card {
  background: white;
  position: relative;
  //width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin: 10px 0px;
  box-shadow: 0 10px 20px 0 rgba(46,91,255,0.07);

  &.active { border-left: 2px solid $blue1; }
}
.tria-card-header {
  padding: 32px 24px 0 24px;
  background-color: white;
}
.tria-card-title {
  color: #8798AD !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  font-family: 'Encode Sans', sans-serif;
  //font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  //font-family: ".SF NS Display";
  line-height: 16px;
  letter-spacing: 1.21px;
  margin-bottom: .75rem;
  text-transform: uppercase;

  & i.tria-card-ellipsis {
    font-size: 22px
  }
}
.tria-card-body {
  padding: 15px 24px 15px 24px;
  flex: 1 1 auto;
}
.tria-card-footer {
  padding: 15px;
}

div[class^="col-"] > .tria-card {
  margin: 10px 5px 10px 5px;
}

/*.integration-card {
  width: 100%;
  min-height: 115px;
  border: 1px solid #E0E7FF;
  border-radius: 5px;
  margin: 7px 0px;
  display: flex;
  align-items: center;
  padding: 20px;

  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;

  &:hover {
      background-color: rgba(224, 231, 255, 0.2);
  }

  & > div > p:first-child {
      word-break: break-word;
      color: $gray6;
  }
  & > div > p:nth-child(2) {
      color: $gray4;
  }
}*/

.integration-card {
  border: 1px solid $gray5;
  border-radius: 5px;
  margin-bottom: 10px;

  width: 100%;

  &:hover, &.checked {
    border: 1px solid $blue1;
  }
  &.no-hover {
    border: 1px solid $gray5;
  }

  &.inactive {
    &:not(.checked) .integration-card-check { display: none; }

    &:hover {
      border: 1px solid $gray4;

      .integration-card-check { display: block; }
    }
  }

  &.checked {
    position: relative;
    border: 1px solid $blue1;
  }

  & i.integration-card-check, & img.integration-card-check {
    color: $blue1;
    position: absolute;
    right: -7px;
    top: -6px; 
    --fa-primary-color: white;
    --fa-secondary-opacity: "1.0";
    --fa-secondary-opacity: "1.0";
  }
  & i.integration-card-check {
    font-size: 18px;
  }
  & img.integration-card-check {
    height: 18px;
    width: 18px;
  }
}

.integration-card-container {
  display: flex;
  flex-wrap: wrap;

  & > .integration-card {
    margin: 0px 20px 12px 0px;
  }
}