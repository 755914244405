.event-alert {
    border-left: solid 4px $red;
}
  
.event-warning {
    border-left: solid 4px $yellow;
}

.event-anomaly {
    border-left: solid 4px #91F0F4;
}
.bm-alert {
    color: $red;
}
.bm-warning {
    color: $yellow;
}
.bm-anomaly {
    color: #91F0F4;
}

ul.bm-details {
    list-style-type: circle;
}

.bm-status-btn.btn-sm:hover {
    background-color: #0C618D !important;
    color: white !important;
  }

// Do not display the breadcrumb list items in AWS troubleshooting docs
.row-fluid-wrapper.row-depth-1.row-number-8,
.row-fluid-wrapper.row-depth-1.row-number-11 {
    display: none;
}
div.hs-breadcrumb-menu-wrapper {
    display: none;
}

#hs_cos_wrapper_module_152416502623339 > h2 {
    margin-bottom: 0px !important;
    margin-top: 15px !important;
}

@media (min-width: 770px) {
    .top-row-mobile {
        display: none;
    }
    .event-time-desktop {
        margin-left: 30%;
        position: absolute;
    }
    .event-source-desktop {
        margin-left: 50%;
        position: absolute;
    }
    .source-text-desktop {
        margin-top: 2px;
    }
    .event-source-mobile {
        display: none;
    }

}

@media (max-width: 770px) {
    .top-row-desktop {
        display: none;
    }
    .source-text-mobile {
        margin-top: 2px;
    }
}