.gauge-col-wrapper {

    min-width: 260px;
    /*@media (min-width: 576px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 700px) {
        flex: 0 0 40%;
        max-width: 40%;
    }*/
    @media (min-width: 900px) {
        flex: 0 0 30%;
        max-width: 30%;

    }
    @media (min-width: 1200px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
}

.gauge-inner-dots > g > path {
    fill: $gray5;
    stroke: white;
}
.gauge-outer-ticks > g > path {
    fill: $gray5;  
}
.gauge-outer-ticks > g > path[color=blue] {
    fill: $blue1;
}
.gauge-outer-ticks > g > path[color=yellow] {
    fill: $yellow1
}
.gauge-outer-ticks > g > path[color=red] {
    fill: $red1
}
// Gauage value label
.gauge-outer-ticks > text:first-of-type {
    transform: translateY(10px);
    fill: #2E384D !important;
    font-family: "Encode Sans", sans-serif;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 47px;
}
// Gauge unit label
.gauge-outer-ticks > text:last-of-type {
    transform: translateY(40px);
    fill: #8798AD !important;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 15px;
} 


.comp-gauge-inner-dots > g > path {
    fill: $gray5;
    stroke: white;
}
/*
.comp-gauge-outer-ticks > g > path {
    fill: $gray5;  
}
.comp-gauge-outer-ticks > g > path[color=blue] {
    fill: $blue1;
}
.comp-gauge-outer-ticks > g > path[color=yellow] {
    fill: $yellow1
}
.comp-gauge-outer-ticks > g > path[color=red] {
    fill: $red1
}
*/
// Gauage value label
.comp-gauge-outer-ticks > text:first-of-type {
    transform: translateY(-5px);
    fill: #2E384D !important;
    font-family: "Encode Sans", sans-serif;
    font-size: 38px;
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 47px;
}
.comp-gauge-outer-ticks.small > text:first-of-type {
    font-size: 32px;
}
.comp-gauge-outer-ticks > text:nth-of-type(2) {
    transform: translateY(20px);
    fill: #8798AD !important;
    font-weight: 500;
    font-family: 'Encode Sans', sans-serif;
    font-size: 13px;
    letter-spacing: 1.12px;
    line-height: 15px;
} 
// Gauge unit label
.comp-gauge-outer-ticks > text:last-of-type {
    transform: translateY(40px);
    fill: #8798AD !important;
    font-weight: 500;
    font-family: 'Encode Sans', sans-serif;
    font-size: 13px;
    letter-spacing: 1.12px;
    line-height: 15px;
} 





:root {
    --gutter: 20px;
}
  
.hs-container {
    display: grid;
    //grid-template-columns: var(--gutter) 1fr var(--gutter);
    grid-template-columns: 0px 1fr var(--gutter);
    grid-gap: var(--gutter) 0;
    align-content: start;
    overflow-y: scroll;
}

.hs-container > * {
    grid-column: 2 / -2;
}

.hs-container > .full {
    grid-column: 1 / -1;
}

.hs {
    display: grid;

    //grid-gap: calc(var(--gutter) / 2);
    //grid-template-columns: 10px;

    grid-template-rows: minmax(150px, 1fr);
    grid-auto-flow: column;
    // Maybe don't use ???
    //grid-auto-columns: calc(50% - var(--gutter) * 2);

    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(.75 * var(--gutter));
    margin-bottom: calc(-.25 * var(--gutter));
}

.hs:before,
.hs:after {
    content: '';
    width: 0px;
}

ul.hs {
    list-style: none;
    padding: 0;
}

.hs > li,
.hs-item {
    scroll-snap-align: center;
    //padding: calc(var(--gutter) / 2 * 1.5);
    padding: calc(var(--gutter) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.no-scrollbar {
    scrollbar-width: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
}


.grid-test {
    display: grid;

    grid-gap: calc(var(--gutter) / 2);
    grid-template-columns: 10px;

    grid-template-rows: minmax(150px, 1fr);
    grid-auto-flow: column;
    // Maybe don't use ???
    //grid-auto-columns: calc(50% - var(--gutter) * 2);

    overflow-x: scroll;
    scroll-snap-type: x proximity;
    padding-bottom: calc(.75 * var(--gutter));
    margin-bottom: calc(-.25 * var(--gutter));

}