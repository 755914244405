// stylelint-disable declaration-no-important

// Width and height

// Loop over the width and height properties
@each $prop, $abbrev in (width: w, height: h) {
  // Generate non-responsive classes (e.g., .w-25, .h-50)
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { 
      #{$prop}: $length !important; 
    }
  }

  // Loop over the breakpoints to generate responsive classes
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} { 
          #{$prop}: $length !important; 
        }
      }
    }
  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }
